<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/LayoutDemo.vue";

export default {
    components: {
        Link,
        Head,
    },
    layout: Layout,
};
</script>

<template>

    <Head title="404" />
    <main class="max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
        <section class="flex w-full h-[100vh] items-center justify-center">
            <div class="w-full lg:w-9/12 mx-auto">
                <div class="flex justify-center">
                    <img class="w-11/12 md:w-3/4" src="../../images/404.png" alt="" />
                </div>
                <h1 class="p-0 text-xl md:text-2xl font-bold text-left text-gray-900 box-border whitespace-no-wrap mb-7 text-center">
                    Aradığınız Sayfayı Bulamadık. <br />
                    Sayfa silinmiş ya da taşınmış olabilir. <br />
                </h1>
                <div class="w-full flex justify-center">
                    <Link href="/" class="hover:bg-black hover:opacity-90 transition-all duration-300 delay-200 flex justify-center items-center bg-kbgreen text-white rounded-full py-2 px-4 lg:px-6 self-center text-2xl font-bold"> Ana Sayfa</Link>
                </div>
            </div>
        </section>
    </main>
</template>
